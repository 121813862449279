.cs-card.cs-style1 {
  position: relative;
  border-radius: 15px;
  display: block;
  margin: 2vh;
  
  &:hover {
    filter: drop-shadow(0px 0px 30px #C59571);
  }
  img {
    width: 100%;
    border-radius: inherit;
  }
  .cs-card_overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    // background: #000000;
    opacity:  0.5 ;
  }
  
  .cs-card_overlay_second {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background: #000000;
    opacity:  0.1 ;
  }

  .cs-card_info {
    position: absolute;
    padding: 45px 25px 0;
    bottom: 5%;
    left: 0;
    span {
      display: block;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      margin-bottom: 10px;
    }
  }
  .cs-card_title {
    font-size: 22px;
    margin: 0;
    line-height: 1.5em;
    color: #fff;
  }
}

@media screen and (max-width: 1400px) {
  .cs-card.cs-style1 .cs-card_title {
    font-size: 20px;
  }
  .cs-card.cs-style1 .cs-card_info {
    padding: 40px 18px 0;
  }
}
@media screen and (max-width: 768px) {

  .cs-card.cs-style1 .cs-card_info {
    padding: 10px !important;
  }
}


@media screen and (max-width: 1199px) {
  .cs-card.cs-style1 .cs-card_title {
    font-size: 20px;
  }
  .cs-card.cs-style1 .cs-card_info {
    padding: 40px 30px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
  .cs-card_info{
    bottom: -30% !important;
  }
}


/*
.row.d-flex.justify-content-center {
  display: flex;
  flex-wrap: wrap;
}

.col-lg-5.mt-5 {
  flex: 0 0 calc(50% - 30px);  Adjust the width as needed 
}
*/

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Display three cards in each row */
  gap: 20px; /* Adjust the gap as needed */
}

.grid-container > * {
  width: 100%;
}


.card_title_bg{
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow:  15px 35px 50px rgba(0, 0, 0, 0.3) ;
  padding: 7px;
  border-radius: 10px;
  backdrop-filter: blur(5px); }