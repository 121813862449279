/*--------------------------------------------------------------
## Basic Color
----------------------------------------------------------------*/

:root {
  --white: #fff; /*fff  */
  --black: #161616; /* 161616 */
  --primary: #000000; /* FEFEFE */
  --secondary: rgba(var(--primary), 0.7);
  --ternary: #000; /* 999696 */
  --border: #4d4d4d; /*4D4D4D  */
  --gray: #ede1cf; /*181818*/

  --accent: #c59571;
  //var(--accent): #d3c5ba ;
  --accent2: #000;
}

footer {
  color: var(--secondary) !important;
}

/* .svgIcon{
    fill:#C59571 !important;
}

.svgIcon{
    fill:#C59571 !important;
}
 */

.svgIcon {
  fill: #c59571 !important;
  color: #c59571;
}
.svgIcon svg * {
  fill: revert-layer;
}
